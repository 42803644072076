import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
import { Link } from "gatsby";
import { SOLUTIONS_URL } from "../constants/pageUrls";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`We are very excited to share this post with you today because not only is it `}<a parentName="p" {...{
        "href": "https://www.usa.gov/flec"
      }}>{`Financial Capability Month`}</a>{`, but today is also `}<a parentName="p" {...{
        "href": "https://cfsinnovation.org/"
      }}>{`CFSI`}</a>{`’s celebration of `}<a parentName="p" {...{
        "href": "https://cfsinnovation.org/news/finhealthmattersday/"
      }}>{`#FinHealthMatters Day`}</a>{`, something we celebrate every day. You can read more about CFSI's approach to the day `}<a parentName="p" {...{
        "href": "https://medium.com/@CFSInnovation/finhealthmatters-day-should-be-every-day-for-everyone-9edcd31222c8"
      }}>{`here`}</a>{`.`}</p>
    <p>{`In celebration of the day, we invite you to meet Dawn and witness her incredible story of transformation:`}</p>
    <div className="player-frame"><iframe width="560" height="315" src="https://www.youtube.com/embed/zicChYIBtIw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
    <p><em parentName="p">{`Direct link to this video: `}<a parentName="em" {...{
          "href": "https://www.youtube.com/watch?v=zicChYIBtIw"
        }}>{`https://www.youtube.com/watch?v=zicChYIBtIw`}</a></em></p>
    <p>{`This video is the first release in a project PayPerks has embarked on to document the impact of the products provided by our partners and the education and motivation provided by PayPerks for those products.`}</p>
    <p>{`In this case, we're talking about the `}<a parentName="p" {...{
        "href": "https://www.usdirectexpress.com/"
      }}>{`Direct Express`}{`®`}</a>{` card, the mobile app for that card, and `}<a parentName="p" {...{
        "href": "https://www.prnewswire.com/news-releases/mastercard-and-comerica-team-up-with-new-york-city-startup-payperks-to-reward-direct-express-debit-mastercard-cardholders-for-learning-181236481.html"
      }}>{`PayPerks for Direct Express`}{`®`}</a>{`.  This card and our program enabled Dawn to completely change her financial situation, going from living paycheck to paycheck, sometimes running out of funds before the next check arrived, to having a significant savings reserve and a much better understanding of how to manage her money and use her card in a convenient way to live a better life.`}</p>
    <p><strong parentName="p">{`Financial Capability to Financial Health (to overall Health)`}</strong></p>
    <p>{`PayPerks was founded to create financial capability for underserved consumers. We do this by working with a variety of great partners, often in concert together, to implement solutions that help consumers learn about and understand existing products on the market and help improve those products directly through analytics, learning, and feedback.  (We can now apply these `}<Link to={SOLUTIONS_URL} mdxType="Link">{`solutions`}</Link>{` beyond payments, across industries.)`}</p>
    <p>{`We do not stop though at just `}<em parentName="p">{`learning`}</em>{`, which some may call `}<em parentName="p">{`Financial Literacy`}</em>{`; instead, we focus on turning that knowledge into action, which we refer to as `}<em parentName="p">{`Financial Capability`}</em>{`, and it’s this action which leads to `}<em parentName="p">{`Financial Health`}</em>{`.`}</p>
    <p>{`This works because the implementation of financial capability skills is powerful, beyond ways most of us initially imagine. We might first think of someone developing a skill to save a few dollars by eliminating unnecessary fees each month through better product usage. This alone is a great outcome, but the more important win is the confidence that comes from skill, because that confidence enables someone to feel empowered to make even more change.`}</p>
    <p>{`For example, someone might act on the additional information in PayPerks on budgeting, savings, and other money management tips, to learn skills that lead towards real behavior improvements, such as being able to build a savings reserve which is one of the key elements of Financial Health.`}</p>
    <p>{`And the creation of Financial Health in turn fosters even more confidence, a new sense of safety, security and wellbeing, and enables other changes that improve the quality of someone's overall health and general wellbeing.`}</p>
    <p><strong parentName="p">{`Happy `}<a parentName="strong" {...{
          "href": "https://cfsinnovation.org/news/finhealthmattersday/"
        }}>{`#FinHealthMatters`}</a>{` Day, indeed!`}</strong></p>
    <p><em parentName="p">{`We will continue to post more videos like this as we speak to more PayPerks users. If you're a PayPerks user and you have a story you want us to tell, write into PayPerks Help and let us know!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      